/** @jsx jsx */
import { jsx } from 'theme-ui';
import { LocalizedLink } from './LocalizedLink';

interface Props {
  to: string;
}
export const UnderlineLink: React.FC<Props> = ({ to, children }) => (
  <LocalizedLink
    to={to}
    sx={{
      variant: 'variants.navlink',
      textDecoration: 'underline',
    }}
  >
    {children}
  </LocalizedLink>
);

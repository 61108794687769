/** @jsx jsx */
import { Box, jsx } from 'theme-ui';
import { Container } from '../components/Container';
import { P, H1, H2, H3, H4, H5 } from '../components/Text';
import { LocalizedLink } from '../components/LocalizedLink';
import { UnderlineLink } from '../components/UnderlineLink';
import { Link, Button } from 'rebass';
import { useTranslation } from '../i18n/TranslationContext';
import { ProgramLayout } from '../layouts/ProgramLayout';

export interface SpeakersListProps {
  pageContext: { speakers: Speaker[] };
}

type Speaker = {
  name: string;
  title: string;
  bio: string;
  link: string;
  twitter: string;
  photo: string;
  slug: string;
  kind: string;
  images?: {
    src: string;
    srcSet: string;
  };
};

const SpeakerImage: React.FC<React.ComponentProps<'img'>> = ({
  src,
  ...restProps
}) => (
  <img
    sx={{ maxWidth: '100%', my: 2 }}
    src={src || 'https://place-hold.it/800x600'}
    {...restProps}
  />
);

const KeynoteSpeaker: React.FC<{
  name: string;
  src?: string;
  bio?: string;
  title?: string;
  twitterUrl: string;
  slug: string;
  gridProps: { [key: string]: string };
}> = ({ name, src, bio, title, twitterUrl, slug, gridProps }) => (
  <Box
    sx={{
      ...gridProps,
    }}
  >
    <H4>
      <UnderlineLink to={`/program/${slug}`}>{name}</UnderlineLink>
    </H4>
    <H5>{title}</H5>
    <LocalizedLink to={`/program/${slug}`}>
      <SpeakerImage alt={name} src={src} />
    </LocalizedLink>
    <P dangerouslySetInnerHTML={{ __html: bio }} />
    {twitterUrl && (
      <Button variant="secondary" as="a" href={twitterUrl} target="blank">
        Connect on Twitter
      </Button>
    )}
  </Box>
);

const Speaker: React.FC<{
  name: string;
  src?: string;
  slug: string;
}> = ({ name, src, slug }) => (
  <Box>
    <H5>
      <UnderlineLink to={`/program/${slug}`}>{name}</UnderlineLink>
    </H5>

    <LocalizedLink to={`/program/${slug}`}>
      <SpeakerImage alt={name} src={src} />
    </LocalizedLink>
  </Box>
);

const SpeakersList: React.FC<SpeakersListProps> = ({ pageContext }) => {
  const t = useTranslation();

  const { speakers } = pageContext;
  const keynoteSpeakers = speakers.filter(
    speaker => speaker.kind === 'keynote',
  );
  const nonKeynoteSpeakers = speakers.filter(
    speaker => speaker.kind !== 'keynote' && speaker.kind !== 'qa',
  );

  const gridPropsPosition = [
    {
      gridColumn: ['1', '3 / span 4'],
    },
    {
      gridColumn: ['1', '8 / span 4'],
    },
    {
      gridColumn: ['1', '1 / span 4'],
    },
    {
      gridColumn: ['1', '7 / span 4'],
    },
    {
      gridColumn: ['1', '2 / span 4'],
    },
    {
      gridColumn: ['1', '9 / span 4'],
    },
  ];

  return (
    <ProgramLayout title="Speakers" programDays={['0', '1', '2', '3']}>
      {/*This is sketchy AF gotta figure out a better way*/}
      <Container>
        <H1>{t('speakers_title')}</H1>
        <H3>{t('speakers_subtitle')}</H3>
        <Box
          sx={{
            mt: '5',
            display: 'grid',
            gridTemplateColumns: ['1fr', 'repeat(12, 1fr)'],
            rowGap: '35px',
          }}
        >
          {keynoteSpeakers.map((s, i) => {
            return (
              <KeynoteSpeaker
                key={s.name}
                name={s.name}
                bio={s.bio}
                title={s.title}
                twitterUrl={s.twitter}
                src={s.images?.src}
                slug={s.slug}
                gridProps={
                  gridPropsPosition[i] || { gridColumn: ['1', '1 / span 12'] }
                }
              />
            );
          })}
        </Box>
        <hr
          sx={{
            borderStyle: 'solid',
            borderColor: 'text',
            my: 5,
            borderBottom: 'none',
          }}
        />
        <H2>Speakers</H2>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gap: '35px',
          }}
        >
          {nonKeynoteSpeakers.map(s => {
            return (
              <Speaker
                key={s.name}
                name={s.name}
                src={s?.images?.src}
                slug={s.slug}
              />
            );
          })}
        </Box>
      </Container>
    </ProgramLayout>
  );
};

export default SpeakersList;
